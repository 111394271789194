import React from 'react';

import { Hero } from './Components/Hero';
import { Navbar } from './Components/Navbar';
import About from './Components/About';
import { Subjects } from './Components/Subjects';
import { USP } from './Components/USP';
import { Footer } from './Components/Footer';
import ContactPageWithCarousel from './Components/ContactPageWithCarousel';

const App = () => {
  return (
    <div className="relative min-h-screen bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
      <Navbar />
      
      {/* Section for Contact Page with Carousel */}
      <div className="flex flex-col lg:flex-row justify-center items-center px-4 py-16 bg-gray-100">
        <ContactPageWithCarousel />
      </div>

      <Hero />
      <About />
      <Subjects />
      <USP />
      <Footer />
    </div>
  );
};

export default App;
