import React from 'react'
import heroimg from '../Assets/heroimg.png'

export const Hero = () => {
  return (
    <div className="mt-48 container mx-auto px-6">
      <div className="flex flex-col lg:flex-row items-center justify-center space-y-8 lg:space-y-0 lg:space-x-6">
        
        <div className="flex flex-col space-y-6 lg:w-1/2">
          <h1 className="text-5xl lg:text-6xl font-semibold leading-tight">
            Let Your Kid grow with future
          </h1>
          <p className="text-gray-500 text-md leading-relaxed max-w-lg">
            An innovative way of teaching the lessons of the future world across every subject for your children. Papa Learners is a one-stop platform where your kids can explore and learn all subjects with ease.
          </p>
          <button className="text-center px-2 py-4 bg-blue-800 text-white text-sm rounded-md hover:bg-blue-700 transition-all duration-300 ease-in-out max-w-xs">
            Our Courses
          </button>
        </div>
        
       
        <img 
  src={heroimg} 
  alt='hero' 
  className='w-full lg:w-[55%] h-auto object-cover' 
/>

      </div>
    </div>
  )
}
