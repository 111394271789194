import React, { useEffect, useState } from 'react';
import kidimg from '../Assets/kidstudy.png';

const textItems = [
  {
    title: "Foundation Focus",
    description: "We specialize in building strong basics for primary-level students, filling the gap left by curriculum-focused coaching."
  },
  {
    title: "Personalized Local Learning",
    description: "Offering a district-level approach for a more personalized, engaging experience for young learners."
  },
  {
    title: "Support for Busy Parents",
    description: "We provide homework help and study habit building, making learning fun and stress-free for both students and parents."
  },
];

export const USP = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textItems.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className='mt-24 px-4 lg:px-16'> {/* Adjusted padding for better layout */}
      <div className='w-full bg-red-700 h-auto py-12'>
        <div className='container mx-auto'>
          <div className='flex flex-col lg:flex-row items-center justify-between w-full lg:w-4/5 mx-auto space-y-8 lg:space-y-0 lg:space-x-12'> {/* Adjusted space between items */}
            {/* Text Section */}
            <div className='flex flex-col space-y-6 lg:w-1/2 lg:pr-12'> {/* Adjusted padding on the right for larger screens */}
              <h1 className='text-4xl text-white font-semibold'>Why Papa Learners?</h1>
              <h3 className='text-white text-2xl font-medium'>
                {textItems[currentIndex].title}
              </h3>
              <p className='text-gray-200 text-lg'>
                {textItems[currentIndex].description}
              </p>
            </div>

            {/* Image Section */}
            <div className='lg:w-1/2'>
              <img 
                src={kidimg} 
                alt='Kid studying' 
                className='w-full h-auto object-cover rounded-lg shadow-lg'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
