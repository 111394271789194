import React, { useState, useEffect } from "react";
import FormfacadeEmbed from "@formfacade/embed-react";
import img1 from "../Assets/Image-1.png";
import img2 from "../Assets/Image-2.png";
import img3 from "../Assets/Image-3.png";
import img4 from "../Assets/Image-6.png";
import img5 from "../Assets/Image-7.png";
import img6 from "../Assets/Image-8.png";
import img7 from "../Assets/Image-9.png";
import img8 from "../Assets/Image-4.png";
import img9 from "../Assets/Image-5.png";

const ContactPageWithCarousel = () => {
  // Array with the imported images
  const images = [img1, img2, img3, img8, img9, img4, img5, img6, img7];

  // State for managing current image index
  const [currentImage, setCurrentImage] = useState(0);

  // Effect to handle automatic image switching
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [images.length]);

  return (
    <div className="container mx-auto px-4 py-8 lg:py-16" style={{ paddingTop: "120px" }}> {/* Adjust the top padding */}
      <div className="flex flex-col lg:flex-row items-center justify-between">
        
        {/* Image Carousel */}
        <div className="lg:w-1/2 w-full mb-8 lg:mb-0 lg:h-[500px]"> {/* Set a fixed height */}
          <div className="relative w-full h-64 lg:h-full overflow-hidden rounded-lg">
            {images.map((image, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
                  index === currentImage ? "opacity-100 z-10" : "opacity-0 z-0"
                }`}
                style={{ transition: "opacity 1s ease-in-out", zIndex: index === currentImage ? 1 : 0 }} // Ensures proper stacking
              >
                <img
                  src={image}
                  className="w-full h-full object-contain" // Change to object-contain
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Contact Form */}
        <div className="lg:w-1/2 w-full lg:pl-8 lg:h-[500px] flex items-center"> {/* Set the same fixed height */}
          <div className="p-8 bg-white shadow-lg rounded-xl w-full">
            <h2 className="text-4xl font-bold text-center mb-6 text-gray-800">
              Contact Us
            </h2>
            <p className="text-center text-gray-600 mb-6">
              Have questions? Fill out the form below and we’ll get back to you.
            </p>

            {/* Form Embed */}
            <FormfacadeEmbed
              formFacadeURL="https://formfacade.com/include/117594866107940517714/form/1FAIpQLSdindESp8Du72sgCvSy9o9JKhbbGXzclFHtHZJpNI6cZIfGbQ/classic.js/?div=ff-compose"
              onSubmitForm={() => console.log('Form submitted')}
            />

            <style>
              {`
              /* Hide watermark */
              .ff-watermark {
                display: none !important;
              }
              
              /* Custom form styling */
              .ff-form {
                padding: 20px;
              }
              
              /* Custom submit button */
              .ff-submit {
                background-color: #3b82f6;
                color: white;
                padding: 0.75rem 1.5rem;
                border-radius: 0.375rem;
                font-weight: 600;
                cursor: pointer;
                text-align: center;
                margin-top: 1.5rem;
              }
              
              .ff-submit:hover {
                background-color: #2563eb;
              }
              `}
            </style>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPageWithCarousel;
