import React from 'react';

const About = () => {
  const cards = [
    {
      title: 'Focus Area Group',
      description: '6 to 16 years / Class 1 to 10 / Anyone who has doubts and questions.',
    },
    {
      title: 'Methodology',
      description: 'Our mission is to explain any topic / doubt / question in a way that even a 6-year-old can understand clearly, building a strong foundational knowledge on the subject.',
    },
    {
      title: 'Subjects covered',
      description: 'Basically the academic subjects of a Class 1 to 10 student / whatever anyone wants to learn / clarify.',
    },
  ];

  return (
    <div className='mt-32'>
      <div className='bg-gray-50 w-full'>
        <div className='flex flex-col justify-center items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12'>
          <h1 className='mt-8 text-center text-4xl font-semibold md:text-5xl'>
            About Papa Learners
          </h1>
          <p className='text-center text-lg text-gray-700 mt-4 mb-8 px-4 md:px-0'>
            Welcome to Papa Learners, where we foster a love for learning in children!
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full'>
            {cards.map((card, index) => (
              <div
                key={index}
                className='bg-white shadow-md p-6 flex flex-col justify-between transition-all duration-300 rounded-lg'
              >
                <div className='flex flex-col space-y-2 text-gray-600 hover:text-blue-700 transition-all duration-300'>
                  <h2 className='text-xl font-semibold'>{card.title}</h2>
                  <p>{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
