import React from 'react';

export const Subjects = () => {
  return (
    <div className='mt-24 px-4'>
      <div className='flex flex-col justify-center items-center space-y-8'>
        {/* Title */}
        <div className='px-4 py-2 bg-gray-100 rounded-lg text-center'>
          <h3 className='text-black font-semibold'>Our Subjects</h3>
        </div>

        {/* Subtitle */}
        <h2 className='text-4xl md:text-5xl font-medium text-center'>
          Elevate Your Kids' Education with Unified Intelligence
        </h2>

        {/* Explore Button */}
        <div className='px-8 py-2 border border-black text-center hover:bg-blue-700 hover:text-white hover:border-white transition-all duration-300 cursor-pointer'>
          <h3>Explore All Categories</h3>
        </div>

        {/* Subjects Grid */}
        <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 md:gap-6 justify-center items-center'>
          {['Mathematics', 'Science', 'Social', 'Physics', 'Chemistry', 'Botany', 'Zoology', 'History', 'Geography', 'English', 'Tamil', 'Telugu', 'Kannada', 'Hindi', 'Chinese', 'Japanese', 'Malayalam', 'Vietnamese'].map((subject, index) => (
            <div key={index} className='px-6 py-4 bg-gray-100 text-center hover:bg-blue-700 hover:text-white transition-all duration-300 rounded-lg'>
              <h2>{subject}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
